import ReturnPoint from "../store/returnPoint/ReturnPoint";
import DocumentTitleUtil from "./DocumentTitleUtil";

/**
 * @typedef {Object} PathDescriptor as the name implies it describes the path that is to be constructed and will be passed on to the Paths constructor.
 * @property {string} name - needs to be the same as the key of the pathlist object!
 * @property {string} uri - the last segment of the path, e.g. ticket
 * @property {Path} parent - the parent path, should be an instance of the Path-class
 * @property {array} legacyHashes - the legacy hashes of this path to redirect old links
 * @property {string} idType - Determines which id type should be used for the path,
 * valid are "ID","UUID" and "LICENSEPLATE" (see RedirectUtil.ID_TYPES) everything else
 * will result in no identification segment
 * @property {boolean} expectAction - Whether the path expects an action at the end
 * @property {boolean} soundPath - Wether the path is sound i.e. if there is actually a page to be shown (Default: true)
 */

class Path {
    /**
     *
     * @param {PathDescriptor} pathDescription object describing the path object to be created.
     */
    constructor({
        name,
        uri,
        parent,
        legacyHashes = [],
        idType = "NONE",
        expectAction = false,
        soundPath = true
    }) {
        this.name = name;
        this.parent = parent;

        const pathSubSegments = ["", uri];
        switch (idType) {
            case RedirectUtil.ID_TYPES.ID:
                pathSubSegments.push(RedirectUtil.PLACEHOLDER.ID);
                break;
            case RedirectUtil.ID_TYPES.UUID:
                pathSubSegments.push(RedirectUtil.PLACEHOLDER.UUID);
                break;
            case RedirectUtil.ID_TYPES.UUIDSMART:
                pathSubSegments.push(RedirectUtil.PLACEHOLDER.UUIDSMART);
                break;
            case RedirectUtil.ID_TYPES.LICENSEPLATE:
                pathSubSegments.push(RedirectUtil.PLACEHOLDER.LICENSEPLATE);
                break;
            default:
        }
        if (expectAction) {
            pathSubSegments.push(RedirectUtil.PLACEHOLDER.ACTION);
        }
        const compoundURI = pathSubSegments.join("/");
        this.uri = (parent ? parent.uri : "") + compoundURI;
        this.hash = (parent ? parent.hash : "#") + compoundURI;

        this.legacyHashes = legacyHashes;

        // if (this.legacyHashes && this.legacyHashes.length) {
        //     console.info(`Legacy redirect to ${this.hash} is active for`, this.legacyHashes);
        // }

        this.paramIndices = [];
        if (pathSubSegments.length > 2) {
            this.hash.split("/").forEach((s, i) => {
                if (Object.values(RedirectUtil.PLACEHOLDER).includes(s)) {
                    this.paramIndices.push(i);
                }
            });
        } else {
            this.paramIndices = (this.parent && this.parent.paramIndices) || [];
        }

        this.soundPath = soundPath;
    }

    withParams = (...params) => {
        if (!this.paramIndices || !params || !params.length) {
            return this.hash;
        }
        const splitHash = this.hash.split("/");
        this.paramIndices.forEach((index, i) => (splitHash[index] = params[i]));
        const result = splitHash.join("/");
        return result;
    };
}

class PrivateRedirectUtil {
    static idRegex = /\/[A-Z][A-Z]?[0-9]{10}\//;
    static uuidRegex = /\/[a-z0-9]{8}-(?:[a-z0-9]{4}-){3}[a-z0-9]{12}\//;
    static uuidWithoutActionRegex = /\/[a-z0-9]{8}-(?:[a-z0-9]{4}-){3}[a-z0-9]{12}$/;
    static uuidSmartRegex = /smartview\/[a-z0-9]{8}-(?:[a-z0-9]{4}-){3}[a-z0-9]{12}/;
    static licenseRegex = /\/[A-Z0-9]{7,8}$/;

    static extractParams = (path = window.location.hash) => {
        const regexes = [
            PrivateRedirectUtil.idRegex,
            PrivateRedirectUtil.uuidRegex,
            PrivateRedirectUtil.uuidWithoutActionRegex,
            PrivateRedirectUtil.uuidSmartRegex,
            PrivateRedirectUtil.licenseRegex
        ];
        regexes.push(
            ...Object.values(RedirectUtil.ACTIONTYPE).map((value) => new RegExp("/" + value))
        );
        const matches = regexes.reduce((arr, regex) => {
            const matchArr = path.match(regex);
            if (matchArr && matchArr && matchArr.length) {
                arr.push(matchArr[0].replace(/\//g, ""));
            }
            return arr;
        }, []);
        return matches;
    };

    static validateAndReplacePath = (path) => {
        if (!path) {
            return RedirectUtil.defaultPath;
        }

        const validPaths = Object.values(RedirectUtil.pathList).filter((p) => p.soundPath);

        let generalPath = path
            .replace(PrivateRedirectUtil.idRegex, "/" + RedirectUtil.PLACEHOLDER.ID + "/")
            .replace(
                PrivateRedirectUtil.uuidSmartRegex,
                "smartview/" + RedirectUtil.PLACEHOLDER.UUIDSMART
            )
            .replace(PrivateRedirectUtil.uuidRegex, "/" + RedirectUtil.PLACEHOLDER.UUID + "/")
            .replace(
                PrivateRedirectUtil.uuidWithoutActionRegex,
                "/" + RedirectUtil.PLACEHOLDER.UUID
            )
            .replace(PrivateRedirectUtil.licenseRegex, "/" + RedirectUtil.PLACEHOLDER.LICENSEPLATE);

        Object.values(RedirectUtil.ACTIONTYPE).forEach(
            (a) =>
                (generalPath = generalPath.replace("/" + a, "/" + RedirectUtil.PLACEHOLDER.ACTION))
        );

        const newPath =
            validPaths.find((p) => p.hash === generalPath || p.hash === path) ||
            validPaths.find(
                (p) =>
                    p.legacyHashes &&
                    p.legacyHashes.length &&
                    (p.legacyHashes.includes(generalPath) || p.legacyHashes.includes(path))
            ) ||
            RedirectUtil.defaultPath;

        // console.log("Validate and redirect:", path, generalPath, newPath);

        return newPath;
    };

    static determineSection = (location = window.location.hash) => {
        const path = location.replace(/^#/, "").replace(/^\//, "");
        let environment = "LKW-WALTER";
        if (path.startsWith("partner")) {
            environment = "PARTNER";
        } else if (path.startsWith("data")) {
            environment = "DATA";
        }
        document.documentElement.setAttribute("data-section", environment);
    };

    static getRedirectHash = (path, ...params) => {
        const target = PrivateRedirectUtil.validateAndReplacePath(
            typeof path === "object" ? path.hash : path
        );

        return target ? target.withParams(...params) : "";
    };

    static redirect = (path, ...params) => {
        const target = PrivateRedirectUtil.getRedirectHash(path, ...params);

        if (!target) {
            window.location.hash = RedirectUtil.defaultPath.hash;
        } else if (window.location.hash !== target) {
            window.location.hash = target;
        }
    };

    static redirectSetReturnPoint = (path = window.location.hash, returnPoint, ...params) => {
        if (returnPoint) {
            ReturnPoint.set(returnPoint);
        }

        PrivateRedirectUtil.redirect(path, ...params);
    };
}

export default class RedirectUtil {
    static ID_TYPES = {
        ID: "ID",
        UUID: "UUID",
        UUIDSMART: "UUIDSMART",
        LICENSEPLATE: "LICENSEPLATE"
    };

    static PLACEHOLDER = {
        ID: ":id",
        UUID: ":uuid",
        UUIDSMART: ":uuidSmart",
        LICENSEPLATE: ":licensePlate",
        ACTION: ":action"
    };

    static ACTIONTYPE = {
        VIEW: "view",
        EDIT: "edit",
        COPY: "copy",
        CHAT: "chat",
        AGREEMENT: "agreement"
    };

    static defaultPath = {};

    // pre-defined list for autocompletion
    static pathList = {
        LKWWALTER: {},
        PARTNER: {},
        DATA: {},
        DATA_MANAGEMENT: {},
        SMARTVIEW: {},
        BASEDATA: {},
        LKWWALTER_DASHBOARD: {},
        LKWWALTER_TICKET: {},
        LKWWALTER_TICKET_DETAIL: {},
        LKWWALTER_TICKET_DETAIL_NEW: {},
        LKWWALTER_ORDER: {},
        LKWWALTER_ORDER_DETAIL: {},
        LKWWALTER_TRAILER: {},
        LKWWALTER_TRAILER_DETAIL: {},
        LKWWALTER_TRAILER_DETAIL_NEW: {},
        LKWWALTER_TRAILER_MULTIEDIT: {},
        LKWWALTER_INSPECTION: {},
        LKWWALTER_INSPECTION_TRAILER: {},
        LKWWALTER_DAMAGE: {},
        LKWWALTER_DEBTOR: {},
        LKWWALTER_DEBTOR_DETAIL: {},
        LKWWALTER_REPORT: {},
        SW2000: {},
        PARTNER_TICKET: {},
        PARTNER_TICKET_DETAIL: {},
        PARTNER_TICKET_DETAIL_NEW: {},
        PARTNER_ORDER: {},
        PARTNER_ORDER_DETAIL: {},
        DATA_ADDRESS: {},
        DATA_ADDRESS_DETAIL: {},
        DATA_MANAGEMENT_USER: {},
        DATA_MANAGEMENT_TRANSLATION: {},
        DATA_MANAGEMENT_SHIPMENT: {},
        DATA_MANAGEMENT_SETTINGS: {},
        DATA_MANAGEMENT_NEWS: {},
        DATA_MANAGEMENT_TASKS: {},
        SMARTVIEW_TRAILER: {},
        SMARTVIEW_ORDERS: {},
        SMARTVIEW_ORDER_DETAIL: {},
        SMARTVIEW_TICKET_DETAIL: {},
        BASEDATA_TRAILERTYPE: {},
        BASEDATA_TRAILERSTATE: {},
        BASEDATA_DAMAGESTATE: {},
        BASEDATA_TICKETSTATE: {},
        MONITORING: {},
        MONITORING_OVERVIEW: {}
    };

    static validURIs = [];

    static createPathList() {
        /**
         * @param {PathDescriptor} pathDescriptor object describing the path object to be created and added to the map
         */
        const createAndMapPath = (pathDescriptor) => {
            const path = new Path(pathDescriptor);
            this.pathList[pathDescriptor.name] = path;
        };

        // #region LKW-Walter paths
        createAndMapPath({name: "LKWWALTER", uri: "lkwwalter", soundPath: false});
        createAndMapPath({
            name: "LKWWALTER_DASHBOARD",
            parent: this.pathList.LKWWALTER,
            uri: "dashboard",
            legacyHashes: ["#/dashboard"]
        });
        createAndMapPath({
            name: "LKWWALTER_TICKET",
            parent: this.pathList.LKWWALTER,
            uri: "ticket",
            legacyHashes: ["#/ticket"]
        });
        createAndMapPath({
            name: "LKWWALTER_TICKET_DETAIL",
            parent: this.pathList.LKWWALTER,
            uri: "ticket-detail",
            legacyHashes: ["#/ticket-detail/:uuid/:action"],
            idType: RedirectUtil.ID_TYPES.UUID,
            expectAction: true
        });
        createAndMapPath({
            name: "LKWWALTER_TICKET_DETAIL_NEW",
            parent: this.pathList.LKWWALTER,
            uri: "ticket-detail",
            legacyHashes: ["#/ticket-detail/:action"],
            expectAction: true
        });
        createAndMapPath({
            name: "LKWWALTER_ORDER",
            parent: this.pathList.LKWWALTER,
            uri: "order",
            legacyHashes: ["#/order"]
        });
        createAndMapPath({
            name: "LKWWALTER_ORDER_DETAIL",
            parent: this.pathList.LKWWALTER,
            uri: "order-detail",
            legacyHashes: ["#/order-detail/:uuid/:action"],
            idType: RedirectUtil.ID_TYPES.UUID,
            expectAction: true
        });
        createAndMapPath({
            name: "LKWWALTER_TRAILER",
            parent: this.pathList.LKWWALTER,
            uri: "trailer",
            legacyHashes: ["#/trailer"]
        });
        createAndMapPath({
            name: "LKWWALTER_TRAILER_DETAIL",
            parent: this.pathList.LKWWALTER,
            uri: "trailer-detail",
            legacyHashes: ["#/trailer-detail/:uuid/:action"],
            idType: RedirectUtil.ID_TYPES.UUID,
            expectAction: true
        });
        createAndMapPath({
            name: "LKWWALTER_TRAILER_DETAIL_NEW",
            parent: this.pathList.LKWWALTER,
            uri: "trailer-detail",
            legacyHashes: ["#/trailer-detail/:action"],
            expectAction: true
        });
        createAndMapPath({
            name: "LKWWALTER_TRAILER_MULTIEDIT",
            parent: this.pathList.LKWWALTER,
            uri: "trailer-multiedit"
        });
        createAndMapPath({
            name: "LKWWALTER_INSPECTION",
            parent: this.pathList.LKWWALTER,
            uri: "inspection",
            legacyHashes: ["#/inspection"]
        });
        createAndMapPath({
            name: "LKWWALTER_INSPECTION_TRAILER",
            parent: this.pathList.LKWWALTER,
            uri: "inspection",
            legacyHashes: ["#/inspection/:uuid"],
            idType: RedirectUtil.ID_TYPES.UUID
        });
        createAndMapPath({
            name: "LKWWALTER_DAMAGE",
            parent: this.pathList.LKWWALTER,
            uri: "damage",
            legacyHashes: ["#/damage"]
        });
        createAndMapPath({
            name: "LKWWALTER_DEBTOR",
            parent: this.pathList.LKWWALTER,
            uri: "debtor",
            legacyHashes: ["#/debtor"]
        });
        createAndMapPath({
            name: "LKWWALTER_DEBTOR_DETAIL",
            parent: this.pathList.LKWWALTER,
            uri: "debtor-detail",
            legacyHashes: ["#/debtor-detail/:uuid/:action"],
            idType: RedirectUtil.ID_TYPES.UUID,
            expectAction: true
        });
        createAndMapPath({
            name: "LKWWALTER_REPORT",
            parent: this.pathList.LKWWALTER,
            uri: "report",
            legacyHashes: ["#/report"]
        });
        // #endregion

        // #region Partner paths
        createAndMapPath({name: "PARTNER", uri: "partner", soundPath: false});
        createAndMapPath({name: "PARTNER_TICKET", parent: this.pathList.PARTNER, uri: "ticket"});
        createAndMapPath({
            name: "PARTNER_TICKET_DETAIL",
            parent: this.pathList.PARTNER,
            uri: "ticket-detail",
            idType: RedirectUtil.ID_TYPES.ID,
            expectAction: true
        });
        createAndMapPath({
            name: "PARTNER_TICKET_DETAIL_NEW",
            parent: this.pathList.PARTNER,
            uri: "ticket-detail",
            expectAction: true
        });
        createAndMapPath({name: "PARTNER_ORDER", uri: "order", parent: this.pathList.PARTNER});
        createAndMapPath({
            name: "PARTNER_ORDER_DETAIL",
            parent: this.pathList.PARTNER,
            uri: "order-detail",
            idType: RedirectUtil.ID_TYPES.ID,
            expectAction: true
        });
        // #endregion

        // #region data paths
        createAndMapPath({name: "DATA", uri: "data", soundPath: false});
        createAndMapPath({
            name: "DATA_ADDRESS",
            uri: "address",
            legacyHashes: ["#/address"],
            parent: this.pathList.DATA
        });
        createAndMapPath({
            name: "DATA_ADDRESS_DETAIL",
            parent: this.pathList.DATA,
            uri: "address-detail",
            legacyHashes: ["#/address-detail/:uuid/:action"],
            idType: RedirectUtil.ID_TYPES.UUID,
            expectAction: true
        });
        createAndMapPath({
            name: "DATA_MANAGEMENT",
            uri: "management",
            parent: this.pathList.DATA,
            soundPath: false
        });
        createAndMapPath({
            name: "DATA_MANAGEMENT_USER",
            parent: this.pathList.DATA_MANAGEMENT,
            uri: "user",
            legacyHashes: ["#/user"]
        });
        createAndMapPath({
            name: "DATA_MANAGEMENT_TRANSLATION",
            parent: this.pathList.DATA_MANAGEMENT,
            uri: "translation",
            legacyHashes: ["#/translation"]
        });
        createAndMapPath({
            name: "DATA_MANAGEMENT_SHIPMENT",
            parent: this.pathList.DATA_MANAGEMENT,
            uri: "shipment",
            legacyHashes: ["#/shipment"]
        });
        // Unneeded link
        // createAndMapPath({
        //     name: "DATA_MANAGEMENT_INSPECTION",
        //     parent: this.pathList.DATA_MANAGEMENT,
        //     uri: "inspection"
        // });
        createAndMapPath({
            name: "DATA_MANAGEMENT_SETTINGS",
            parent: this.pathList.DATA_MANAGEMENT,
            uri: "settings",
            legacyHashes: ["#/settings"]
        });
        createAndMapPath({
            name: "DATA_MANAGEMENT_NEWS",
            parent: this.pathList.DATA_MANAGEMENT,
            uri: "news",
            legacyHashes: ["#/news"]
        });
        createAndMapPath({
            name: "DATA_MANAGEMENT_TASKS",
            parent: this.pathList.DATA_MANAGEMENT,
            uri: "tasks",
            legacyHashes: ["#/tasks"]
        });
        // #endregion

        // #region Smartview
        createAndMapPath({name: "SMARTVIEW", uri: "smartview"});
        createAndMapPath({
            name: "SMARTVIEW_TRAILER",
            uri: "smartview",
            idType: RedirectUtil.ID_TYPES.UUIDSMART
        });
        createAndMapPath({
            name: "SMARTVIEW_ORDERS",
            parent: this.pathList.SMARTVIEW_TRAILER,
            uri: "orders"
        });
        createAndMapPath({
            name: "SMARTVIEW_ORDER_DETAIL",
            parent: this.pathList.SMARTVIEW_TRAILER,
            uri: "order",
            idType: RedirectUtil.ID_TYPES.UUID
        });
        createAndMapPath({
            name: "SMARTVIEW_TICKET_DETAIL",
            parent: this.pathList.SMARTVIEW_TRAILER,
            uri: "ticket"
        });
        // #endregion

        // #region base data paths
        createAndMapPath({name: "BASEDATA", uri: "basedata", soundPath: false});
        createAndMapPath({
            name: "BASEDATA_TRAILERTYPE",
            parent: this.pathList.BASEDATA,
            uri: "trailertype"
        });
        createAndMapPath({
            name: "BASEDATA_TRAILERSTATE",
            parent: this.pathList.BASEDATA,
            uri: "trailerstate"
        });
        createAndMapPath({
            name: "BASEDATA_DAMAGESTATE",
            parent: this.pathList.BASEDATA,
            uri: "damagestate"
        });
        createAndMapPath({
            name: "BASEDATA_TICKETSTATE",
            parent: this.pathList.BASEDATA,
            uri: "ticketstate"
        });
        // #endregion

        createAndMapPath({name: "MONITORING", uri: "monitoring", soundPath: false});
        createAndMapPath({
            name: "MONITORING_OVERVIEW",
            parent: this.pathList.MONITORING,
            uri: "state"
        });
        // #endregion

        // #region Special paths
        createAndMapPath({
            name: "SW2000",
            uri: "sw2000",
            idType: RedirectUtil.ID_TYPES.LICENSEPLATE
        });
        // #endregion

        this.defaultPath = this.pathList.LKWWALTER_DASHBOARD;
        PrivateRedirectUtil.determineSection();
    }

    static getRedirectHash = (path, ...params) => {
        return PrivateRedirectUtil.getRedirectHash(path, ...params);
    };

    static redirect = (path, ...params) => {
        PrivateRedirectUtil.redirect(path, ...params);
    };

    static redirectSetReturnPoint = (path, returnPoint, ...params) => {
        PrivateRedirectUtil.redirectSetReturnPoint(path, returnPoint, ...params);
    };

    static handleHashChange = () => {
        const params = PrivateRedirectUtil.extractParams(window.location.hash);
        PrivateRedirectUtil.redirect(window.location.hash, ...params);
        DocumentTitleUtil.generateTitleFromURL();
        PrivateRedirectUtil.determineSection();
    };

    static openInNewWindow = (path, ...params) => {
        const url =
            typeof path === "object" || (params && params.length)
                ? PrivateRedirectUtil.getRedirectHash(path, ...params)
                : path;
        window.open(url, "_blank");
    };

    /**
     * Will open the given url in a new window and close it afterwards to
     * trigger handling of browser protocol functions without destroying
     * current page lifecycle. (e.g. for mailto: and tel: urls)
     * @param url the target url to open (e.g. mailto:<somebodys-mailadress>)
     */
    static triggerProtocolUrl = (url) => {
        const openedWin = window.open(url, "_blank");
        if (openedWin && !url.includes("tel:")) {
            setTimeout(() => openedWin.close(), 50);
        }
    };
}
